/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700&display=swap");

ion-app {
  margin-top: env(safe-area-inset-top);
}

.backbtn {
  position: absolute;
  left: 2%;
  top: 1%;
}

.btn-mob {
  height: 54px;
  margin-top: 15%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #f6f6f6;
  border-radius: 8px;
}

.google-btn {
  margin-top: 20px;
  --background: white;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.54);
  border: solid 1px;
  border-radius: 8px;
  height: 54px;
}

.fb-btn {
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #f6f6f6;
  border-radius: 8px;
  height: 54px;
}
.mob-input {
  height: 54px;
  margin-top: 15%;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  background: #f6f6f6;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
  background: #f6f6f6;
  ion-input {
    border: 0;
  }
}

ion-content {
  background-color: var(--app-page-bg);
  width: 100%;
  height: 100%;
  --keyboard-offset: 0px !important;
}

.login-bottom-bg {
  background-color: var(--app-page-bg);
  background-image: url(/assets/icon/login_footer.svg) !important;
  background-repeat: no-repeat !important;
  background-position: left bottom !important;
  background-size: 100% !important;
}

.hor-center {
  display: flex;
  justify-content: center;
}

.alert-popover-width {
  --width: 280px;
}

ion-action-sheet {
  --ion-color-primary: #000;
  .action-sheet-title {
    text-align: left !important;
    margin-left: 10px;
  }

  .action-sheet-button-inner {
    justify-content: flex-start !important;
  }

  .action-sheet-button {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #767676 !important;
  }
}

ion-checkbox {
  --size: 20px;
  --background-checked: var(--brand-color) !important;
}

.alert-div {
  width: 280px;
  height: 310px;
  border-radius: 18px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.alert-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alert-primary-btn {
  height: 38px;
  width: 100%;
  color: #f6f6f6 !important;
  background: #1f1f1f;
  border-radius: 30px;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
}

.alert-cross {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.alert-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}

.alert-subtext {
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  text-align: center;
}

.page-green-bg {
  background-color: var(--app-header-bg);
  width: 100%;
  height: 100%;
}

.page-white-bg {
  background-color: var(--app-page-bg-after-login);
  width: 100%;
  height: 100%;
  padding: 15px;
  border-top-right-radius: var(--page-top-border-radius);
  border-top-left-radius: var(--page-top-border-radius);
}

.page-title {
  height: 44px;
  width: 100%;
  text-align: center;
  border-top-left-radius: var(--page-top-border-radius);
  border-top-right-radius: var(--page-top-border-radius);
  border-bottom: 1px solid gray;
  font-weight: 500;
  font-size: 16px;
  color: #767676;
  text-transform: uppercase;
  padding-top: 8px;
  background: #ffffff;
}

.no-padding {
  padding: 0;
}

ion-toast {
  --ion-color-base: black;
  color: white;
}

.form-field-error-text {
  color: red;
  font-size: 10px;
  margin-top: 5px;
}

ion-label {
  font-size: 13px;
  font-weight: 500;
  color: #767676;
}

.app-logo {
  width: 128px;
  height: 128px;
}

.page-top-border {
  border-top-right-radius: var(--page-top-border-radius);
  border-top-left-radius: var(--page-top-border-radius);
}

.text-xs {
  font-weight: 500;
  font-size: 11px;
}

.text-sm {
  font-weight: 500;
  font-size: 13px;
}

.text-md {
  font-weight: 500;
  font-size: 16px;
}

.text-lg {
  font-weight: 500;
  font-size: 17px;
}

.text-link {
  color: var(--brand-color) !important;
}

.text-light {
  color: #767676;
}

.text-bold {
  font-weight: 600 !important;
}

.pin-container {
  width: 250px;
}

.pin-heading {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #1288cf !important;
  width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pin-content {
  margin-top: -15px;
}

.pin-map-icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.pin-addrss-text-container {
  display: flex;
  align-items: center;
}

.pin-addrss-text {
  font-weight: 400;
  font-size: 12px;
  color: #464646 !important;
}

.pin-info-container {
  display: flex;
  margin-top: -10px;
}

.pin-star-image {
  width: 12px;
  height: 11px;
  margin-right: 2px;
  color: var(--brand-color) !important;
}

.pin-info-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.footer-padding {
  padding: 10px;
}

.footer-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-btn {
  width: 90%;
  height: 44px;
  color: white;
  background-color: var(--brand-color);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}

input[type="checkbox"] {
  accent-color: var(--brand-color);
}

input[type="checkbox"]:after {
  box-shadow: none !important;
}

div,
button,
p {
  color: black !important;
}

input,
select,
ion-card,
ion-footer {
}

.primary-button {
  height: 48px;
  width: 100%;
  color: #f6f6f6 !important;
  background: #1f1f1f;
  border-radius: 30px;
  font-weight: 600;
  font-size: 14px;
}

.primary-button-disabled {
  background: #bcbcbc !important;
}

.vehicle-bg {
  background: url(/assets/img/bg-vehicle.svg) !important;
}

.error-container {
  border-radius: 30px;
  min-height: 50px;
  background: #ff6767;
  opacity: 0.99;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 0 15px;
}

.error-icon {
  width: 18px;
  height: 16px;
}

.error-text {
  color: white !important;
  font-weight: 500;
  font-size: 10px;
  margin-left: 10px;
  flex: 1;
}

.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 10px 0;
}

.charging-img {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.page-padding {
  padding-left: 10px;
  padding-right: 10px;
}

ion-toast.custom-toast {
  --background: #e9f8ff;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #4b4a50;
}

ion-toast.custom-toast::part(message) {
  color: #000;
  font-weight: 500;
}

ion-toast.custom-toast::part(button) {
  border-left: 1px solid #d2d2d2;
  color: #030207;
  font-size: 15px;
}

ion-alert {
  --background: #fff !important;
  font-weight: 400;
  font-size: 11px;
  line-height: 38px;
  text-align: center;

  .alert-head {
    h2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 36px;
    }
  }

  .alert-button:last-child {
    margin: 0 25px 20px 25px;
    height: 38px;
    width: 90%;
    color: #f6f6f6 !important;
    background: #1f1f1f;
    border-radius: 30px;
    font-weight: 600;
    font-size: 14px;

    span {
      color: #fff;
    }
  }
}
